<!--个人中心，对应旧版本的user.html-->
<template>
    <div>
        <time-range-selector v-on:refreshTime="refreshTime"></time-range-selector>
        <table class="count_container" border="1">
            <tr>
                <th style="width: 40%">套餐</th>
                <th v-if="is_SeparateOrders==0">核销数据(全部账号)</th>
                <th>核销数据(当前账号)</th>
            </tr>
            <tr v-for="(item , index) in list" :key="index">
                <td>{{item.gooods_name}}</td>
                <td  v-if="is_SeparateOrders==0">{{item.check}}</td>
                <td>{{item.recheck}}</td>
            </tr>
        </table>
        <check-float-nav></check-float-nav>
    </div>
</template>
<script>
  import TimeRangeSelector from '../../components/common/TimeRangeSelector'
  import CheckFloatNav from '../../components/check/CheckFloatNav'
  import { globalConfig } from '../../utils/mixin'
  export default {
    name: 'Count',
    mixins: [globalConfig],
    components: { CheckFloatNav, TimeRangeSelector },
    data () {
      return {
        list: [],
        is_SeparateOrders: 0,
        currentLabel: 0
      }
    },
    methods: {
        // 日期动作
        refreshTime: function (start, end) {
            this.axios.post(this.apiUrl + 'checkh5/order/count', {
                startTime: start,
                endTime: end
            }).then((response) => {
                console.log(response)
                const data = response.data
                this.list = data.list
                this.is_SeparateOrders = data.account.is_SeparateOrders
            })
        }
    },
    created () {
    },
    mounted () {
    },
    computed: {
    }
  }
</script>
<style scoped>
    .selector-wrapper{
        width: 100vw;
        /*display: flex;*/
        background-color: #FFFFff;
        box-shadow:0 8px 10px -8px #999;
        padding: 0.5rem 0;
    }
    .selector-label-wrapper{
        width: 100%;
        display: flex;
        color: #999;
        font-size: 3vw;
        padding: 3vw 1vw;
        box-sizing: border-box;
    }
    .selector-label{
        background: #f8f8f8;
        padding: 0.25vw 2vw;
        border-radius: 4vw;
        margin: 0 1.2vw;
    }
    .selector-date-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-around;
        padding: 0 1rem 0 0;
        width: 80%;
        box-sizing: border-box;
    }
    .selector-date-input{
        width: 24vw;
        height: 5vw;
        border-radius: 5vw;
        border: 1px solid #b8b8b8;
        font-size: 2.8vw;
        text-align: center;
    }
    .selector-font{
        margin: 0 1vw;
        font-size: 3.2vw;
        color: #333;
    }

    .count_container {
        width: 95%;
        margin: 1rem auto;
    }
    td,th {
        text-align: center;
        padding: 0.5vw 0.6vw;
        font-size: 3.2vw;
    }
</style>
